import moment from 'moment'

export const validateNews = (news = []) => {
  const noticesExpired = news.filter((news) =>
    moment(news.dateTo, 'YYYY/MM/DD').isSameOrBefore(moment())
  )
  if (noticesExpired.length === news.length) {
    return true
  } else {
    return false
  }
}
